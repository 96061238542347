.bio {
  position: relative;
  flex-basis: 40% !important;
  margin-bottom: 30px !important;
}

.bioimage {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.biotextcontainer {
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.6);;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  color: white
}

.bio:hover .bioimage {
  opacity: 0.2;
}

.bio:hover .biotextcontainer {
  opacity: 1;
}

.biotext {
  font-size: 20px;
}

.biotextcontainer a {
  color: white;
  text-align: right;
  text-decoration: underline;
  width: 100%;
}