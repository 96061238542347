// Styling for the footer
footer {
    padding: 25px 0;
    text-align: center;
    color: fade-out(white, .3);
    background-color: $gray-darker;
    p {
        font-size: 12px;
        margin: 0;
    }
    ul {
        margin-bottom: 0;
        li {
            a {
                font-size: 12px;
                color: fade-out(white, .3);
                &:hover,
                &:focus,
                &:active,
                &.active {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
    .list-inline-item {
        margin-left: .5rem;
        margin-right: .5rem
    }
}
