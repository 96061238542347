// Bootstrap overrides for this template
.bg-primary {
    background: $theme-primary;
    background: -webkit-linear-gradient($theme-primary, darken($theme-primary, 5%));
    background: linear-gradient($theme-primary, darken($theme-primary, 5%));
}

.text-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.btn-outline {
    color: white;
    border: 1px solid;
    border-color: white;
    &:hover,
    &:focus,
    &:active,
    &.active {
        color: black;
        border-color: white;
        background-color: white;
    }
}

.btn {
    border-radius: 300px;
}

.btn-xl {
    @include alt-font;
    font-size: 11px;
    padding: 15px 45px;
}
