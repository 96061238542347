.process {
  h3 {
    color: $theme-secondary;
    font-weight: 700;
  }
  b {
    font-weight: 1000;
    font-style: italic;
  }
}

section.safety {
  padding: 0px;
  .carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      line-height: 1;
    }
  }
}