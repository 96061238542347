// Styling for the navbar
#mainNav,
#sideNav {
    border-color: fade-out($gray-darker, .95);
    background-color: white;
    @include transition-all;
    @include heading-font;
    .navbar-brand {
        @include heading-font;
        &:hover,
        &:focus {
            color: darken($theme-secondary, 10%);
        }
        font-size: 2rem;
        font-weight: 700;
    }
    .navbar-toggler {
        font-size: 12px;
        padding: 8px 10px;
        color: $gray-darker;
    }
    .navbar-nav {
        > li {
            > a {
                font-size: 12px;
                font-weight: 300;
                @include alt-font;
                &.active {
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
            > a,
            > a:focus {
                color: $gray-darker;
                &:hover {
                    color: $theme-secondary;
                }
            }
        }
    }
    @media (min-width: 992px) {
        border-color: transparent;
        background-color: transparent;
        .navbar-brand {
            color: white;
            &:hover,
            &:focus {
                color: fade-out(white, .2);
            }
        }
        .navbar-nav > li > a {
            &.active {
                color: white !important;
            }
        }
        .navbar-nav > li > a,
        .navbar-nav > li > a:focus {
            color: fade-out(white, .3);
            &:hover {
                color: white;
            }
        }
        &.navbar-shrink {
            border-color: fade-out($gray-darker, .9);
            background-color: white;
            .navbar-brand {
                color: $gray-darker;
                &:hover,
                &:focus {
                    color: $theme-secondary;
                }
            }
            .navbar-nav > li > a {
                &.active {
                    color: $theme-secondary !important;
                }
            }
            .navbar-nav > li > a,
            .navbar-nav > li > a:focus {
                color: $gray-darker;
                &:hover {
                    color: $theme-secondary;
                }
            }
        }
    }
}
